import Rollbar from "rollbar/dist/rollbar.umd";
import * as Turbo from "@hotwired/turbo";
import posthog from "posthog-js";

import { turboConfirmMethod } from "./application/lib/turbo_confirm_method";

import "./application/images/video_preview.jpg";
import "./application/images/visibility_preview.jpg";
import "./application/images/visibility_map.jpg";
import "./application/images/visibility_status.jpg";
import "./application/images/visibility_files.jpg";

import "./application/images/communication_preview.jpg";
import "./application/images/communication_chat.jpg";
import "./application/images/communication_docs.jpg";

import HighlightCurrentLinkElement from "./application/elements/highlight_current_link";

if (!window.customElements.get("highlight-current-link")) {
  window.customElements.define(
    "highlight-current-link",
    HighlightCurrentLinkElement,
  );
}

function initPostHog() {
  if (document.location.hostname.includes("localhost")) return;
  if (document.location.hostname.includes("herokuapp.com")) return;
  if (document.body.dataset.impersonated === "true") return;
  if (document.body.dataset.currentUserEmail?.includes("@loadar.com")) return;

  posthog.init("phc_WekpFRLnzpC6q9MgBE6ME7MZbd4t3PflvHYHFyMLDtJ", {
    api_host: "https://eu.i.posthog.com",
    enable_recording_console_log: true,
  });

  if (document.body.dataset.currentUserEmail) {
    posthog.identify(document.body.dataset.currentUserEmail);
  }
}

initPostHog();

if (process.env.ROLLBAR_CLIENT_TOKEN) {
  window.Rollbar ||= new Rollbar({
    accessToken: process.env.ROLLBAR_CLIENT_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        javascript: {
          source_map_enabled: true,
        },
      },
      person: {
        id: document.body.dataset.currentUserId,
        email: document.body.dataset.currentUserEmail,
      },
      environment: "production",
    },
  });
}

Turbo.setConfirmMethod(turboConfirmMethod);

window.customElements.define(
  "is-js-render-input",
  class extends HTMLElement {
    connectedCallback() {
      this.children[0].value = true;
    }
  },
);

window.customElements.define(
  "timezone-field-wrapper",
  class extends HTMLElement {
    connectedCallback() {
      this.children[0].value = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  },
);

let originalReferer;

function windowScroll() {
  const navbar = document.getElementById("topnav");

  if (navbar != null) {
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("nav-sticky");
    } else {
      navbar.classList.remove("nav-sticky");
    }
  }
}

const modalVideoHTML = (button) => `
<div class="fixed z-10 top-0 left-0 right-0 bottom-0 overflow-y-auto" aria-labelledby="modal-title" role="modal" aria-modal="true">
  <div class="text-center block p-0 h-screen">
    <div class="fixed top-0 left-0 right-0 bottom-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" data-role="close-modal"></div>
    <div class="mt-20 inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle max-w-6xl w-full">
      <iframe style="height: 70vh; width: 100%" src="${button.dataset.src}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen ></iframe>
    </div>
    <div class="relative z-10">
      <a href="#" onclick="[event.preventDefault(), history.back()]" class="mt-4 btn btn-lg border-2 border-green-600 shadow-lg text-green-600 bg-white rounded-full">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
           <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
           <line x1="5" y1="12" x2="19" y2="12"></line>
           <line x1="5" y1="12" x2="11" y2="18"></line>
           <line x1="5" y1="12" x2="11" y2="6"></line>
        </svg>
        Back
      </a>
      <a href="/meetings" class="mt-4 btn btn-lg border-2 border-red-500 shadow-lg text-white bg-red-500 rounded-full">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
        </svg>
        Book a demo
      </a>
    </div>
  </div>
</div>
`;

const modalUiVideoHTML = (src) => {
  return `
<div id="video_modal" class="fixed z-10 top-0 left-0 right-0 bottom-0 overflow-y-auto" aria-labelledby="modal-title" role="modal" aria-modal="true">
  <div class="text-center block p-0 h-screen">
    <div class="fixed top-0 left-0 right-0 bottom-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" data-role="close-modal"></div>
    <div class="mt-20 inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle max-w-6xl w-full">
      <video class="rounded-md shadow-xl w-full" autoplay src="${src}" width="960px" height="540px" loop="loop" muted="muted" playsinline="" accel-video="true"></video>
    </div>
    <div class="relative z-10">
      <a href="#" onclick="[event.preventDefault(), window.video_modal.parentNode.remove()]" class="mt-4 btn btn-lg border-2 border-green-600 shadow-lg text-green-600 bg-white rounded-full">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
           <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
           <line x1="5" y1="12" x2="19" y2="12"></line>
           <line x1="5" y1="12" x2="11" y2="18"></line>
           <line x1="5" y1="12" x2="11" y2="6"></line>
        </svg>
        Back
      </a>
      <a href="/meetings" class="mt-4 btn btn-lg border-2 border-red-500 shadow-lg text-white bg-red-500 rounded-full">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
        </svg>
        Book a demo
      </a>
    </div>
  </div>
</div>
`;
};

function openVideoModal(button) {
  navigator.sendBeacon("/collect", JSON.stringify({ name: "openVideo" }));

  let container = document.querySelector('[data-role="modal"]');

  if (!container) {
    container = document.createElement("div");
    container.setAttribute("data-role", "modal");

    container.innerHTML = modalVideoHTML(button);
  }

  container.querySelectorAll('[data-role="close-modal"]').forEach((e) => {
    e.addEventListener("click", () => history.go(-1));
  });

  document.body.appendChild(container);
}

function openUiVideoModal(e) {
  navigator.sendBeacon(
    "/collect",
    JSON.stringify({ name: "openUiVideoModal" }),
  );

  let container = document.querySelector('[data-role="modal"]');

  if (!container) {
    container = document.createElement("div");
    container.setAttribute("data-role", "modal");

    container.innerHTML = modalUiVideoHTML(e.target.src);
  }

  container.querySelectorAll('[data-role="close-modal"]').forEach((e) => {
    e.addEventListener("click", () => window.video_modal.parentNode.remove());
  });

  document.body.appendChild(container);
}

function closeModal() {
  document.querySelector('[data-role="modal"]')?.remove();
}

window.addEventListener("scroll", (ev) => {
  ev.preventDefault();
  windowScroll();
});

const Default = {
  defaultTabId: null,
  activeClasses: "text-white bg-green-600",
  inactiveClasses: "hover:text-green-600 hover:bg-gray-50",
  onShow: () => {},
};

class Tabs {
  constructor(items = [], options = {}) {
    this._items = items;
    this._activeTab = options ? this.getTab(options.defaultTabId) : null;
    this._options = { ...Default, ...options };
    this._init();
  }

  _init() {
    if (this._items.length) {
      // set the first tab as active if not set by explicitly
      if (!this._activeTab) {
        this._setActiveTab(this._items[0]);
      }

      // force show the first default tab
      this.show(this._activeTab.id, true);

      // show tab content based on click
      this._items.forEach((tab) => {
        tab.triggerEl.addEventListener("click", () => {
          this.show(tab.id);
        });
      });
    }
  }

  getActiveTab() {
    return this._activeTab;
  }

  _setActiveTab(tab) {
    this._activeTab = tab;
  }

  getTab(id) {
    return this._items.filter((t) => t.id === id)[0];
  }

  show(id, forceShow = false) {
    const tab = this.getTab(id);

    // don't do anything if already active
    if (tab === this._activeTab && !forceShow) {
      return;
    }

    // hide other tabs
    this._items.forEach((t) => {
      if (t !== tab) {
        t.triggerEl.classList.remove(...this._options.activeClasses.split(" "));
        t.triggerEl.classList.add(...this._options.inactiveClasses.split(" "));
        t.targetEl.classList.add("hidden");
        t.triggerEl.setAttribute("aria-selected", false);
      }
    });

    // show active tab
    tab.triggerEl.classList.add(...this._options.activeClasses.split(" "));
    tab.triggerEl.classList.remove(...this._options.inactiveClasses.split(" "));
    tab.triggerEl.setAttribute("aria-selected", true);
    tab.targetEl.classList.remove("hidden");

    this._setActiveTab(tab);

    // callback function
    this._options.onShow(this, tab);
  }
}

document.addEventListener("turbo:load", () => {
  if (window.IntersectionObserver && document.querySelector("video")) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio === 1 && entry.target.paused) {
            entry.target.play();
          }
        });
      },
      { threshold: 1 },
    );

    document
      .querySelectorAll("video")
      .forEach((video) => observer.observe(video));
  }

  originalReferer ||= document.referrer;

  document.querySelectorAll("[data-tabs-toggle]").forEach((triggerEl) => {
    const tabElements = [];
    let defaultTabId = null;
    triggerEl.querySelectorAll('[role="tab"]').forEach((el) => {
      const isActive = el.getAttribute("aria-selected") === "true";
      const tab = {
        id: el.getAttribute("data-tabs-target"),
        triggerEl: el,
        targetEl: document.querySelector(el.getAttribute("data-tabs-target")),
      };
      tabElements.push(tab);

      if (isActive) {
        defaultTabId = tab.id;
      }
    });
    return new Tabs(tabElements, {
      defaultTabId,
    });
  });

  navigator.sendBeacon(
    "/collect",
    JSON.stringify({
      name: "pageView",
      data: { url: document.location.href, original_referer: originalReferer },
    }),
  );

  document.querySelectorAll('[data-role="open-video"]').forEach((e) => {
    e.addEventListener("click", () => openVideoModal(e.target));
  });

  document.querySelectorAll('[data-role="open-ui-video"]').forEach((e) => {
    e.addEventListener("click", openUiVideoModal);
  });

  window.onhashchange = function (e) {
    if (e.newURL.includes("#video")) {
      openVideoModal(document.querySelector('[href="#video"]'));
    } else {
      closeModal();
    }
  };

  document.addEventListener("keydown", (e) => {
    if (e.keyCode === 27) {
      if (document.querySelector('[data-role="modal"]')) {
        history.go(-1);
      }
    }
  });
});
